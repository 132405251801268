import React from "react"
import { graphql } from "gatsby"
import get from 'lodash/get'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import BackgroundImage from '../components/BackgroundImage'
import TipList from '../components/tipList'


const options = {
    renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
};

class authorTemplate extends React.Component {
    render(props) {

    const author = get(this.props, 'data.contentfulAuthor');
    const authorBio = documentToReactComponents(author.bio.json, options);
    const authorTips = get(this, 'props.data.authorTips.edges');


    return(

        <Layout>
            <SEO title={`Author: ${author.name}`} />

            <BackgroundImage height="300px" imageData={author.coverPhoto.fluid} />


            <ProfileWrapper>

                <img src={author.photo.file.url} alt={author.name} style={{ width: `200px`, height: `auto`, marginTop: `-100px`, position: `absolute`, border: `5px solid white` }}/>


                <SocialList>
                    <li>
                        <a href={author.instagram} target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li>
                        <a href={author.website} target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-globe"></i>
                        </a>
                    </li>
                    <li>
                        <a href={'mailto:' + author.email} target="_blank" rel="noopener noreferrer">
                            <i className="far fa-envelope"></i>
                        </a>
                    </li>
                </SocialList>

            </ProfileWrapper>

            <Main>
                <h1>{ author.name }</h1>
                <p><i className="fas fa-map-marker"></i> {author.location} <br/>
                    <i>{author.photographerType}</i></p>

                <h2>Bio</h2>
                {authorBio}

                <TipList data={authorTips} tipHeight="250px" headingText="Recent tips by me" itemWidth="40%" />

            </Main>
        </Layout>
    )


    }
}



export default authorTemplate



export const PageQuery = graphql`
  query AuthorBySlug($slug: String!, $authorName: String) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulAuthor(slug: { eq: $slug }) {
        name
        slug
        instagram
        email
        website
        photographerType
        location
        bio {
          json
        }
        photo {
           file {
            url
          }
          fluid(maxWidth: 1400, maxHeight: 900, resizingBehavior: SCALE) {
            ...GatsbyContentfulFluid
          }
        }
        
        coverPhoto {
           file {
            url
          }
          fluid(maxWidth: 1400, maxHeight: 900, resizingBehavior: SCALE) {
            ...GatsbyContentfulFluid
          }
        }
      }
      
      placeholderImage: file(relativePath: { eq: "author-cover-photo.jpg" }) {
        childImageSharp {
          fixed(width: 1500, height: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      
      authorTips: allContentfulTip(filter: { author: {name: {eq: $authorName}}}, limit: 4, sort: {fields: createdAt, order: DESC}) {
        edges {
          node {
            title
            slug
            timeToRead
            category {
                name
            }
            author {
                name
            }
            image {
              file {
                url
              }
              fluid(maxWidth: 1400, maxHeight: 900, resizingBehavior: SCALE) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
      
      
    }
  `;

const ProfileWrapper = styled.div`
  max-width 800px;
  margin: 0 auto;
`;


const SocialList = styled.ul`
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   list-style: none;
   margin-top: 20px;
   
   li {
       margin-right: 10px;
       a {
        color: #fff;
        font-size: 25px;
        background: #3c7db6;
        border: 1px solid #3c7db6;
        padding: 3px 8px;
        
        &:hover {
            background: transparent;
            color: #3c7db6;
            border: 1px solid #3c7db6;
        }
      }
   }
`;


const Main = styled.div`
  max-width: 800px;
  margin: 70px auto 60px auto;  
  color: white;
  
  h1 {
    font-size: 28px;
    margin: 0;
  }
  
  h2 {
    font-size: 24px;
  }

  @media(max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
  }
`;